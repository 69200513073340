.App {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
